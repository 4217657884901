<template>
  <div class="page_bg" style="margin:0;">
    <!-- 顶部返回 -->
    <!-- <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">{{ type === '1' ? '爆款推荐':'捡漏专区' }}</div>
      <div class="top_icon" />
    </div> -->
    <div style="position: fixed;top: 0;width: 100%;z-index: 9999">
      <div class="search_top  flex-c-s-c" style="background:#ffffff">
        <div class="ser_search  flex-r-sb-c">
          <div />
          <van-icon name="arrow-left" size="0.5rem" @click="backPath" />
          <van-search v-model="searchValue" class="classify_ser" placeholder="请输入商品名称" shape="round" @search="inputSer"
                      @focus="onFocus"
          >
            <template #right-icon>
              <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                          @click="inputSer"
              >搜索</van-button>
            </template>
          </van-search>
          <div />
        </div>
      </div>
      <!-- 顶部过滤 -->
      <serTopFilter ref="serTopFilter" :type="3" :mid="$route.query.id" @changeFilter="changeFilter"
                    @changeFiltercategory="changeFiltercategory" @changeFilterbrand="changeFilterbrand"
      />
    </div>
    <!-- 商品列表区域  -->
    <div class="pro_list" style="margin-top: 2.2rem">
      <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
        <template #default="slotProps">
          <proCard09 :info="slotProps.item" :type="type" />
        </template>
      </MyscrollList>
    </div>
  </div>
</template>

<script>
import { productModule_productList } from '@/api/home'
import MyscrollList from '@/components/MyscrollList'
import proCard09 from '@/components/pro_card_09'
import serTopFilter from '@/components/ser_top_filter'
export default {
  components: { MyscrollList, proCard09, serTopFilter },
  data() {
    return {
      type: '0',
      // 商品列表
      proSearch: { page: 1, limit: 10, mallProductModuleId: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.proSearch.mallProductModuleId = this.$route.query.id
    this.type = this.$route.query.type
    this.getProList()
  },
  activated() {
    console.log('keepalive 进入activerProList')
    if (sessionStorage.getItem('listJump')) {
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.proSearch.proBrand = this.$route.query.id
      this.getProList()
    }
  },
  deactivated() {
    this.$refs.serTopFilter.closePullBox()
    console.log('keepalive 离开activerProList')
  },
  methods: {
    backPath() { window.history.back() },
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      productModule_productList(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },

    // 顶部搜索
    changeFilter(e) {
      console.log(e)
      this.$refs.myList01.load_status_clear()
      this.proSearch.page = 1
      this.proSearch.mallProductModuleId = this.$route.query.id
      if (e === 1 || e === 41) {
        this.proSearch.orderType = ''
        this.proSearch.orderWay = ''
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 2) {
        this.issaleNum = !this.issaleNum
        this.proSearch.orderType = 'SALE_NUM'
        this.proSearch.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 3) {
        this.isprice = !this.isprice
        this.proSearch.orderType = 'PRICE'
        this.proSearch.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 42) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '282'
      }
      if (e === 43) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '281'
      }
      if (e === 44) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '275'
      }
      this.proList = []
      this.getProList()
    },
    changeFiltercategory(id) {
      this.$refs.myList01.load_status_clear()
      this.proSearch.page = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''
      this.proSearch.tagId = ''; this.proSearch.proCategoryId = id
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    changeFilterbrand(brand) {
      this.$refs.myList01.load_status_clear()
      this.proSearch.page = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = brand
      this.proSearch.tagId = ''; this.proSearch.proCategoryId = ''
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    // 获焦事件
    onFocus() {
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      this.searchValue = ''
      this.proList = []
      this.proSearch.page = 1
    },
    inputSer() {
      this.$refs.serTopFilter.closePullBox()
      this.proList = []
      this.proSearch.page = 1
      this.proSearch.proName = this.searchValue
      this.getProList()
    }
  }
}
</script>

<style>
</style>
